<div class="alpha-static-theme">
    <div class="page-header">
        <img class="logo" src="../../../../assets/images/logos/Mobilify-Logo-Stacked-Color.svg" alt="Mobilify Logo" />
    </div>
    <div class="main-body">
        <div class="main-content">
            <h1 class="heading">Unsupported Browser</h1>
            <p class="text">Please consider installing a supported browser to get the most out of the Mobilify&#8480; experience.</p>
            <ul class="browsers">
                <li>
                    <img class="browser-icon" aria-hidden="true" src="../../../../assets/images/browsers/icon_chrome.svg" alt="Chrome browser" />
                    <span class="label">Chrome</span>
                </li>
                <li>
                    <img class="browser-icon" aria-hidden="true" src="../../../../assets/images/browsers/icon_firefox.svg" alt="Firefox browser" />
                    <span class="label">Firefox</span>
                </li>
                <li>
                    <img class="browser-icon" aria-hidden="true" src="../../../../assets/images/browsers/icon_edge.svg" alt="Edge browser" />
                    <span class="label">Edge</span>
                </li>
                <li>
                    <img class="browser-icon" aria-hidden="true" src="../../../../assets/images/browsers/icon_safari.png" alt="Safari browser" />
                    <span class="label">Safari</span>
                </li>
            </ul>
        </div>
        <div class="page-footer">
            <div class="copyright" [innerHTML]="copyright"></div>
            <div class="footer-link">
                <span class="link"><a target="_blank" href="https://submit-irm.trustarc.com/services/validation/0080a1bc-5f59-48fc-993e-83efc1029056">Do Not Sell My Personal Information</a></span> <br />
                <span class="link"><a routerLink="/terms-of-use" target="_blank">Terms of Use</a></span>
                <span class="link"><a [href]="privacyNotice" target="_blank">Privacy Notice</a></span>
            </div>
        </div>
    </div>
</div>