/** MPI-91 - used by AccountSettingsComponent */
import { Component, OnInit, Input } from '@angular/core';
import { Candidate } from '../../../core/models/candidate';
import { CandidateContactInfo } from '../../../core/models/candidate-contact-info.model';
import { PermissionsService } from '../../services/permissions.service';
import { AppConfigService } from '../../services/app-config.service';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CandidateProfilesService } from '../../services/candidate-profiles.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { WorkflowEnablementService } from '../../services/workflow-enablement.service';

/** Candidate Contact Information Component */
@Component({
  selector: 'app-candidate-contact-info',
  templateUrl: './candidate-contact-info.component.html',
  styleUrls: ['./candidate-contact-info.component.scss']
})
export class CandidateContactInfoComponent implements OnInit {
  /**
   * Stores the candidate details
   */
  @Input() candidateContactInfo: CandidateContactInfo;
  /** Holds the company*/
  @Input() candidateCompany: string;
  /** holds candidate id */
  @Input() candidateId: string;
  /** Holds the candidate name initials */
  candidateInitials: string;
  /** Holds the candidate name*/
  candidateName: string;
  /** Holds the candidate contact number */
  phoneNumber: string;
  /** Holds the candidate emailID */
  emailId: string;
  /** holds the candidate country dialing code */
  countryDialingCode: string;
  mobilifyLink = 'MOBILIFY';
  showLaunchLink = false;
  allowedCapabilities = ['Alpha Transferee Portal'];
  hasMobilifyLaunchLink = ['authorize-transferee', 'budget-info'];
  currentUrl:  UrlSegment[];
  currentlyOpenTransfereeWindow: any = null;
  /** Injecting the dependencies */
  constructor(private readonly permissionSvc: PermissionsService,
    private readonly appConfig: AppConfigService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly candidateSvc: CandidateProfilesService,
    private readonly spinner: NgxSpinnerService,
    private readonly workflowEnablementSvc: WorkflowEnablementService) { }

  /**
   * To initialise the component
   */
  ngOnInit() {
     this.activatedRoute.url.subscribe(segments =>
      this.currentUrl = segments
      );
    this.getContactInfo();

    this.workflowEnablementSvc.currentlyOpenTransferee.subscribe(windowObj => {
      this.currentlyOpenTransfereeWindow = windowObj;
    });
  }

  /**
   * Fetching the Candidate Info and Binding the HTML
   */
  getContactInfo() {
    if (this.candidateContactInfo) {
      this.candidateInitials =
        this.candidateContactInfo.candidateName[
        this.candidateContactInfo.candidateName.indexOf(
          this.candidateContactInfo.candidateName.split(',')[1].trim()
        )
        ] + this.candidateContactInfo.candidateName[0];

      this.candidateName =
        `${this.candidateContactInfo.candidateName.split(',')[1]} ${this.candidateContactInfo.candidateName.split(',')[0]}`;
      this.emailId = this.candidateContactInfo.emailId;
      this.phoneNumber = this.candidateContactInfo.phoneNumber.toString();
      this.countryDialingCode = this.candidateContactInfo.countryDialingCode;
      this.showLaunchMobilifyLink();
    }
  }

  getPhoneNumber(phoneNumber) {
    const regex = /^[A-Za-z0-9 ]+$/;
    if (regex.test(phoneNumber)) {
      return `(${this.phoneNumber.substring(
        0,
        3
      )}) ${this.phoneNumber.substring(3, 6)}-${this.phoneNumber.substring(6, this.phoneNumber.length)}`;
    } else {
      return phoneNumber;
    }
  }

  showLaunchMobilifyLink() {
    if (this.hasMobilifyLaunchLink.includes(this.currentUrl[0].path)) {
      this.permissionSvc.getRolesAndCapabilities()
      .then(capabilities => {
        const rolesAndCapabilities = capabilities;
        if (!!rolesAndCapabilities && !!rolesAndCapabilities.roleCapabilities && rolesAndCapabilities.roleCapabilities.length > 0) {
          for (const capability of rolesAndCapabilities.roleCapabilities) {
            for (const capabilityValue of capability.capabilities) {
              if (this.allowedCapabilities.includes(capabilityValue.name)) {
                this.showLaunchLink = true;
                break;
              }
            }
          }
        }
      });
    }
  }

  launchMobilifyLink() {
    this.spinner.show();
    this.candidateSvc.getEncryptedCandidateId(this.candidateId).subscribe({
      next: response => {
        this.spinner.hide();
        if (response) {
          const baseMobilifyUrl = String(this.appConfig.getConfig('transfereePortalUrl'));
          const url = `${baseMobilifyUrl}#/read-only/${response.body.encryptedPartyId}`;
          // ensure only 1 transferee session is opened at a time
          if (this.currentlyOpenTransfereeWindow) {
            this.currentlyOpenTransfereeWindow.close();
          }
          const readOnlyTWindow = window.open(url, '_blank');
          this.workflowEnablementSvc.updateCurrentlyOpenTransferee(readOnlyTWindow);
        }
      },
      error: () => {
        this.spinner.hide();
      }
    });
  }
}
