import { Component, OnInit } from "@angular/core";
import { NotificationsService } from "src/app/core/services/notifications.service";
import { LoggerService } from "src/app/core/services/logger.service";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { NgxSpinnerService } from "ngx-spinner";
import { Clipboard } from "@angular/cdk/clipboard";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from "@angular/forms";
import { WordCountValidators } from './wordcount';
import { GoogleAnalyticsService } from "../../../core/services/google-analytics.service";
import { CallSummarizerService } from "../../../core/services/call-summarizer.service";
import { typos } from './possibleTypos';

@Component({
  selector: "app-call-summarizer",
  templateUrl: "./call-summarizer.component.html",
  styleUrls: ["./call-summarizer.component.scss"],
})
export class CallSummarizerComponent implements OnInit {
  /** Form group name */
  callSummarizerForm: UntypedFormGroup;

  previousSummarizations: {
    summazrizationResult: string,
    htmlSummary: string,
    emailTimeStampMsg: string,
    transcript: string,
    transcriptTimeStamp?: string
  }[] = []

  copyButtonStates: {
    draftCopied: boolean
  }[] = []

  state: {
    startTimeStamp?: string,
    inflight: boolean
  } = { inflight: false }

  focusCard: boolean = false;

  summarizedText: any;

  pasteButtonShow : boolean = true

  generatedSummarization: any;
  
  /** Injecting dependencies */
  constructor(
    private readonly notificationsService: NotificationsService,
    private readonly loggerService: LoggerService,
    public spinner: NgxSpinnerService,
    private readonly callSummarizerService: CallSummarizerService,
    public snackBar: MatSnackBar,
    private clipboard: Clipboard,
    public ga: GoogleAnalyticsService,
    private readonly formBuilder: UntypedFormBuilder,
  ) {
    this.callSummarizerForm = this.formBuilder.group({
      textInput: [
        "",
        Validators.compose([Validators.required, WordCountValidators.min(100), Validators.minLength(6)]),
      ],
    });
  }

  /**To initialize the component */
  ngOnInit() {
    this.spinner.hide();
  }
  pasteContent() {
    navigator.clipboard.readText().then(clipboardText => {
      this.callSummarizerForm.get('textInput').setValue(clipboardText)
    }).catch(error => {
      console.error('Failed to read clipboard contents: ', error);
    });
  }
  generate() {
    this.state.inflight = true
    let startTimeStamp = new Date();
    this.state.startTimeStamp = this.getFormattedDate();
    this.callSummarizerForm.get('textInput').disable()
    this.callSummarizerService.getemailSummeryResponse({ textInput: this.fixTypos(this.callSummarizerForm.value.textInput) }, 'summary').subscribe({
      next: summaryResponse => {
        if (summaryResponse && summaryResponse.status === 200) {
          this.notificationsService.flashNotification('success', 'Transcript Summary created. Working on email generation...', true, 'dismiss');
          this.callSummarizerService.getemailSummeryResponse({ textInput: this.fixTypos(summaryResponse.body.summary) }, 'email').subscribe({
            next: response => {
              if (response && response.status === 200) {
                this.state.inflight = false
                this.generatedSummarization = response.body.emailSummaryResponse
                this.summarizedText = response.body.emailSummaryResponse.replace(
                  /\r?\n/g,
                  "<br />"
                );

                // push into previousSummarizations
                this.previousSummarizations.push({
                  summazrizationResult: response.body.emailSummaryResponse,
                  htmlSummary: response.body.emailSummaryResponse.replace(
                    /\r?\n/g,
                    "<br />"
                  ),
                  emailTimeStampMsg: 'Email Draft is ready!',
                  transcript: this.callSummarizerForm.value,
                  transcriptTimeStamp: this.getFormattedDate(startTimeStamp)
                })
                this.copyButtonStates.push({ draftCopied: false })
                this.callSummarizerForm.get('textInput').enable();
                this.callSummarizerForm.get('textInput').reset();
                this.state.startTimeStamp = undefined
                this.focusCard = true
                setTimeout(() => {
                  this.previousSummarizations[this.previousSummarizations.length - 1].emailTimeStampMsg = ` ${this.getFormattedDate()}`
                  this.focusCard = false
                }, 4000);
                setTimeout(() => {
                  document?.getElementById("latestCard")?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
                }, 0);
              } else {
                this.notifyOFError()
              }
            },
            error: err => {
              this.notifyOFError(err)
              
            },
            complete: () => {
              this.state.startTimeStamp = undefined
              this.callSummarizerForm.get('textInput').enable();
              this.state.inflight = false
              this.ga.triggerGAEvent('call-summarizer-generate-email');
            }
          });
        } else {
          this.notifyOFError()
        }
      },
      error: err => {
        this.notifyOFError(err)
      },
      complete: () => { }
    });
  }

  notifyOFError(err?) {
    this.state.startTimeStamp = undefined
    this.callSummarizerForm.get('textInput').enable();
    this.state.inflight = false
    if(err)
    this.loggerService.error('Failed to generate summary', err);
    this.notificationsService.flashNotification('danger', 'We are unable to process your request at this time. Please try again later.', true, 'dismiss');
  }

  fixTypos(str: string) {
    typos.forEach(typo => {
      str = str.replace(typo.replaceWord, typo.replaceWith)
    })
    return str;
  }

  copyToClipBoard(index: number) {
    this.ga.triggerGAEvent('call-summarizer-copy-clipboard');
    this.clipboard.copy(this.previousSummarizations[index].summazrizationResult);
    this.copyButtonStates[index].draftCopied = true;
    setTimeout(() => {
      this.copyButtonStates[index].draftCopied = false;
    }, 4000);
  }

  getFormattedDate(dateInp?: Date) {
    let now = dateInp ? dateInp : new Date()
    let time = now.toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3")
    let monthName = now.toLocaleString('default', { month: 'long' });
    let year = now.getFullYear()
    let date = now.getDate()
    return `${monthName} ${date}, ${year} ${time}`
  }

  /** Custom error messages */
  getErrorMessage(fieldName) {
    if (fieldName === 'textInput') {
      return this.callSummarizerForm.get('textInput').hasError('minword') ? 'Transcript should have at least 100 Words' : '';
    }
    return '';
  }

  focusFunction() {
    this.pasteButtonShow = false
  }

  focusOutFunction() {
    this.pasteButtonShow = true
  }
}
