import { Injectable } from '@angular/core';
import { MatLegacySnackBarConfig as MatSnackBarConfig, MatLegacySnackBar as MatSnackBar,
         MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition,
         MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition } from '@angular/material/legacy-snack-bar';
import { DuplicateCandidateComponent } from '../../public/project-alpha/candidate-profile/duplicate-candidate/duplicate-candidate.component';
import { CustomSnackbarComponent } from 'src/app/public/custom-snackbar/custom-snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  setAutoHide = true;
  autoHide = 3000;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  addExtraClass = false;

  constructor(public snackBar: MatSnackBar) { }

  flashNotification(...params) {
    const notificationType = params[0];
    const message = params[1];
    const config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = ['warning', 'danger'].includes(notificationType) ? 0 : this.setAutoHide ? this.autoHide : 0;
    config.panelClass = notificationType;
    config.data = message;
    this.snackBar.openFromComponent(CustomSnackbarComponent, config);
  }

  confirmNotification(message) {
    this.snackBar.openFromComponent(DuplicateCandidateComponent, {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      data: 'Invitation Sent',
      duration: (message === 'Email') ? 5000 : 0
    });
  }

}
