import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService } from './base-service';
import { ApiConfigService } from './api-config.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorHandlerService } from './http-error-handler.service';
import { AuthenticationService } from './authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { urlType } from '../models/urlType';
import { isNullOrUndefined } from 'util';
import { BrowserStorageService } from './browser-storage.service';
import { timeout } from 'rxjs/operators';

/**
 * Used to make generic standard API calls.  The base URL for the service calls is based on the configuration.
 */
@Injectable({
  providedIn: 'root'
})
export class BaseClientService extends BaseService {
  /** Variable to store the token  */
  private readonly token: UserContext;

  /**
   * base constructor
   * @param config API Config service injector
   * @param http HTTP Client injector
   * @param errorHandler HTTP error handler injector
   */
  constructor(
    config: ApiConfigService,
    http: HttpClient,
    private readonly errorHandler: HttpErrorHandlerService,
    private readonly authsrvc: AuthenticationService,
    private readonly cookieService: CookieService,
    private readonly browserStorageService: BrowserStorageService
  ) {
    super(config, http);
  }


  /** Getting logged in user details  */
  public getUserDetails(): string {
    let token;
    this.authsrvc.isAuthenticated().then(res => {

      token = res;
      if (token) { return token.accessToken; }
    });
    return null;
  }
  /** Run a GET API call, expecting a response with a single model
   * @param route The endpoint for the request (ie. - '/v1/reports_recent')
   * @param action The action that is performing the request
   * @return A response containing the expected model (single)
   */
  getById<TResponseModel>(
    route: string,
    action = 'error executing requests',
    apiUrlType?: urlType,
    timeOut?: number
  ): Observable<HttpResponse<TResponseModel>> {
    const url = this._getUrl(apiUrlType);
    if (timeOut) {
      return this.http
        .get<TResponseModel>(url + route, {
          params: this.newParams(),
          observe: 'response',
          responseType: 'json',
          headers: this.getRequestHeaders()
        })
        .pipe(
          timeout(timeOut),
          catchError(this.errorHandler.handleHttpErrorResponse(action))
        );
    }
    return this.http
      .get<TResponseModel>(url + route, {
        params: this.newParams(),
        observe: 'response',
        responseType: 'json',
        headers: this.getRequestHeaders()
      })
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }

  /** Run a GET API call, expectiing a response with an array of the expected model
   * @param route The endpoint for the request (ie. - '/v1/reports_recent')
   * @param action The action that is performing the request
   * @return A response containing the expected models (array)
   */
  get<TResponseModel>(
    route: string,
    action = 'error executing requests',
    apiUrlType?: urlType,
    timeOut?: number
  ): Observable<HttpResponse<Array<TResponseModel>>> {
    const url = this._getUrl(apiUrlType);
    if (timeOut) {
      return this.http
        .get<TResponseModel>(url + route, {
          params: this.newParams(),
          observe: 'response',
          responseType: 'json',
          headers: this.getRequestHeaders()
        })
        .pipe(
          timeout(timeOut),
          catchError(this.errorHandler.handleHttpErrorResponse(action))
        );
    }
    return this.http
      .get<TResponseModel>(url + route, {
        params: this.newParams(),
        observe: 'response',
        responseType: 'json',
        headers: this.getRequestHeaders()
      })
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }

  /** Run a PUT API call
   * @param route The endpoint for the request (ie. - '/v1/reports_recent')
   * @param body The object that is being updated
   * @param action The action that is performing the request
   * @return A response containing the expected result (single)
   */
  put<TResponseModel>(
    route: string,
    body: any,
    action = 'error putting request',
    apiUrlType?: urlType,
    timeOut?: number
  ): Observable<HttpResponse<TResponseModel>> {
    const url = this._getUrl(apiUrlType);
    if (timeOut) {
      return this.http
        .put<TResponseModel>(url + route, body, {
          params: this.newParams(),
          observe: 'response',
          responseType: 'json',
          headers: this.getRequestHeaders()
        })
        .pipe(
          timeout(timeOut),
          catchError(this.errorHandler.handleHttpErrorResponse(action))
        );
    }
    return this.http
      .put<TResponseModel>(url + route, body, {
        params: this.newParams(),
        observe: 'response',
        responseType: 'json',
        headers: this.getRequestHeaders()
      })
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }

  /** Run a POST API call
   * @param route The endpoint for the request (ie. - '/v1/reports_recent')
   * @param body The object that is being posted
   * @param action The action that is performing the request
   * @return A response containing the expected result (single)
   */
  post<TResponseModel>(
    route: string,
    body: any,
    action = 'error posting request',
    apiUrlType?: urlType,
    timeOut?: number
  ): Observable<HttpResponse<TResponseModel>> {
    let url = this._getUrl(apiUrlType);
    if (timeOut) {
      return this.http
      .post<TResponseModel>(url + route, body, {
        params: this.newParams(),
        observe: 'response',
        responseType: 'json',
        headers: this.getRequestHeaders()
      })
        .pipe(
          timeout(timeOut),
          catchError(this.errorHandler.handleHttpErrorResponse(action))
        );
    }
    return this.http
      .post<TResponseModel>(url + route, body, {
        params: this.newParams(),
        observe: 'response',
        responseType: 'json',
        headers: this.getRequestHeaders()
      })
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }

  /** Run a DELETE API call
   * @param route The endpoint for the delete request
   * @param action The action that is performing the request
   * @return A response containing the expected result
   */
  delete<TResponseModel>(
    route: string,
    action = 'error delete request',
    apiUrlType?: urlType,
    timeOut?: number
  ): Observable<HttpResponse<TResponseModel>> {
    const url = this._getUrl(apiUrlType);
    if (timeOut) {
      return this.http
      .delete<TResponseModel>(url + route, {
        params: this.newParams(),
        observe: 'response',
        responseType: 'json',
        headers: this.getRequestHeaders()
      })
        .pipe(
          timeout(timeOut),
          catchError(this.errorHandler.handleHttpErrorResponse(action))
        );
    }
    return this.http
      .delete<TResponseModel>(url + route, {
        params: this.newParams(),
        observe: 'response',
        responseType: 'json',
        headers: this.getRequestHeaders()
      })
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }

  private getRequestHeaders(): HttpHeaders {

    // Only send headers which have values to send
    const appContext = this.browserStorageService.getSessionStorageValue('car-ses-con');
    const token = this.cookieService.get('car-ses-tok');
    let headers: HttpHeaders = new HttpHeaders({
      'Authorization': token,
    });
    
    if (appContext !== null && appContext !== undefined) {
      headers = headers.set('app-context', appContext)
    } 
    
    return headers;
  }

  private _getUrl(apiUrlType) {
    switch (apiUrlType) {
      case urlType.accessmgmt:
        return this.accessManagementUrl;
      case urlType.costmodel:
        return this.costModelUrl;
      case urlType.opdata:
        return this.operationalDataUrl;
      default:
        return this.rootUrl;
    }
  }
}

/** It is a class to store user context */
export default interface UserContext {
  /** User Name */
  username: string;
  /** Access Tocken */
  accessToken: string;
  /** ID Tocken */
  idToken: string;
  /** User Roles */
  Roles: string[];
  /** Expiry seconds */
  exp: number;
}
